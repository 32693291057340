import { useGetChallengesQuery } from "services/graphql";

export function useGetChallenges() {
  const { data, loading, error, refetch } = useGetChallengesQuery();

  return {
    challenges: data?.challengesPromoted,
    loading,
    error,
    refetch,
  };
}
