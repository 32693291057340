import React from "react";
import Modal, { CloseModalButton } from "app/components/Modal";
import { ChallengesDetails } from "app/components/ChallengesDetails";
import {
  ChallengeDetailsPageFragment,
  useGetChallengeDetailsQuery,
} from "services/graphql";
import styled from "styled-components";
import LoaderCentered from "../Loader/LoaderCentered";
import Div from "../Div";

interface ChallengeModalProps {
  challengeId: string;
  isOpen: boolean;
  onClose: () => void;
}

const StyledModal = styled(Modal)`
  width: 100%;
  max-width: 768px;
  top: 24px;
  height: calc(100vh - 48px);
  padding: 0;
  margin: 16px;
  background: transparent;
`;

function ChallengeModalInner({
  challenge,
  loading,
  onClose,
}: {
  challenge: ChallengeDetailsPageFragment;
  loading: boolean;
  onClose: () => void;
}) {
  if (!challenge || loading) {
    return <LoaderCentered />;
  }

  return (
    <>
      <Div position="absolute" right="16px" top="16px">
        <CloseModalButton onClick={onClose} />
      </Div>
      <Div borderRadius="24px" overflow="hidden">
        <ChallengesDetails challenge={challenge} />
      </Div>
    </>
  );
}

export function ChallengeModal({ challengeId, onClose }: ChallengeModalProps) {
  const { data, loading } = useGetChallengeDetailsQuery({
    variables: { challengeId },
  });

  return (
    <StyledModal
      onClose={onClose}
      maxWidth="800px"
      onBackgroundClick={onClose}
      isOpen
    >
      <ChallengeModalInner
        challenge={data?.challengeById}
        loading={loading}
        onClose={onClose}
      />
    </StyledModal>
  );
}
