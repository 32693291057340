import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import LoaderFullCentered from "app/components/Loader/LoaderFullCentered";
import { Container, CenterWrapper } from "app/components/RouteComponents";
import { H1 } from "app/components/Typography";
import { ChallengeProgressCard } from "app/components/ChallengeProgressCard";
import { NotFound } from "app/components/NotFound";
import { ChallengeModal } from "app/components/ChallengeModal";
import styles from "./styles.module.scss";
import { useGetChallenges } from "./hooks";

export default function Challenges() {
  const { challenges, loading } = useGetChallenges();
  const [selectedChallengeId, setSelectedChallengeId] = useState<string | null>(
    null
  );

  if (loading) {
    return <LoaderFullCentered />;
  }

  if (!challenges) {
    return <NotFound />;
  }

  return (
    <CenterWrapper>
      <Helmet title="Challenges | STEEZY" />
      <Container>
        <H1 mb={3}>Challenges</H1>
        <div className={styles.challengesContainer}>
          {challenges?.map(challenge => (
            <div key={challenge.id} className={styles.challengeCard}>
              <ChallengeProgressCard
                challenge={challenge}
                onClick={() => setSelectedChallengeId(challenge.id)}
              />
            </div>
          ))}
        </div>
        {selectedChallengeId && (
          <ChallengeModal
            challengeId={selectedChallengeId}
            isOpen={!!selectedChallengeId}
            onClose={() => setSelectedChallengeId(null)}
          />
        )}
      </Container>
    </CenterWrapper>
  );
}
